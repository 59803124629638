import { render, staticRenderFns } from "./ArticleCreate.vue?vue&type=template&id=141a0be2&scoped=true&"
import script from "./ArticleCreate.vue?vue&type=script&lang=js&"
export * from "./ArticleCreate.vue?vue&type=script&lang=js&"
import style0 from "./ArticleCreate.vue?vue&type=style&index=0&id=141a0be2&prod&lang=scss&"
import style1 from "./ArticleCreate.vue?vue&type=style&index=1&id=141a0be2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "141a0be2",
  null
  
)

export default component.exports